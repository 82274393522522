<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="id" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.id" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1100 }">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'info'">
							<div>
								影院名称：{{ record.name }}
							</div>
							<div>
								影院地址： {{ record.address }}
							</div>
						</template>
						<template v-if="column.key === 'goods'">
							<template v-if="record.goodsDetailList">
                <div v-for="item in record.goodsDetailList" class="detailItem">
                  <div style="font-weight: 600;">{{ item.storeGoodsStock.mainTitle || '商品名称' }}</div>
<!--                  <div>卡初始金额：{{ item.storeGoodsStock.initAmount }} 元</div>-->
                  <div>卡等级：{{ item.storeGoodsStock.rechargeCardTitle }}</div>
                </div>
							</template>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['coupon_stored_buy_edit']" type="link" size="small" @click="onEdit(record)">修改</a-button>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		<a-modal v-model:visible="visible" title="修改会员卡商品" width="800px">
			<template #footer>
				<a-button @click="visible = false;">取消</a-button>
				<a-button type="primary" @click="onSubmit">确定</a-button>
			</template>
			<div>
				<a-spin :spinning="loading">
					<chooseGoods v-model:value="goodsIds" :selectItem="goodsList" :id="id" :isRechargeCard="1" type="checkbox" @change="onGoodsListChange"></chooseGoods>
					<div v-if="goodsList && goodsList.length">
						<a-table :columns="tableColumns" :dataSource="goodsList" :pagination="false" :scroll="{x: 1000}" size="small">
							<template #bodyCell="{column, record, index}">
								<template v-if="column.key === 'image'">
									<a-image style="width: 40px;" :src="record.imgUrl"></a-image>
								</template>
								<template v-if="column.key === 'card'">
									<div v-if="record.isCard">
										<a-tag color="orange">{{ ['次卡', '年卡'][record.cardType - 1] }}</a-tag>
										<div v-if="record.cardType === 1">卡次数：{{ record.cardBalanceTimes }}</div>
										<div>卡等级：{{ record.cardTitle }}</div>
									</div>
									<div v-if="record.isRechargeCard">
										<a-tag color="orange">线上会员卡</a-tag>
										<!-- <div>卡初始金额：{{ record.initAmount }} 元</div> -->
										<div>卡等级：{{ record.rechargeCardTitle }}</div>
									</div>
									<div v-if="!record.isRechargeCard && !record.isCard">--</div>
								</template>
								<template v-if="column.key === 'coupon'">
									<div v-if="record.isCoupon">
										<div v-if="record.ticketBatchNum">
											<div>兑换券批次号：<span style="color: rgb(102, 102, 102);">{{ record.ticketBatch?.batchNo }}</span></div>
											兑换券发放数量：<span style="color: rgb(102, 102, 102);">{{ record.ticketBatchNum }}张</span>
										</div>
										<div v-if="record.snackBatchNum">
											<div>卖品券批次号：<span style="color: rgb(102, 102, 102);">{{ record.snackBatch?.batchNo }}</span></div>
											卖品券发放数量：<span style="color: rgb(102, 102, 102);">{{ record.snackBatchNum }}张</span>
										</div>
									</div>
									<span v-else>--</span>
								</template>
								<template v-if="column.key === 'price'">
									<div>销售价：{{ record.nowPrice }}</div>
									<div>成本价：{{ record.costPrice }}</div>
									<div>市场价：{{ record.originPrice }}</div>
								</template>
                <template v-if="column.key === 'action'">
                  <a-button v-permission="['coupon_stored_buy_edit']" type="link" @click="onDelete(record, index)">删除</a-button>
                </template>
							</template>
						</a-table>
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import chooseGoods from '@/components/chooseGoods/chooseGoods.vue';
	import { getOrganizationList, getCinemaList, getCinemaCardGoodsList, updateCinemaCardGoods } from '@/service/modules/cinema.js';
	export default {
		components: {
			Icon,
			chooseGoods
		},
		data() {
			return {
				loading: false,
				id: 0,
				organizationList: [],
				cinemaAllList: [],
				formState: {
					// organizationId: 0,
					id: 0,
				},
				searchData: {},
				list: [],
				columns: [{
					title: '影院国家编码',
					dataIndex: 'code',
					width: 180
				}, {
					title: '影院信息',
					key: 'info'
				}, {
					title: "线上会员卡商品",
					key: 'goods'
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				visible: false,
				goodsIds: [],
				goodsList: [],
				tableColumns: [{
					title: '名称',
					dataIndex: 'mainTitle'
				}, {
					title: '图片',
					key: 'image',
					width: 100
				}, {
					title: '剩余库存',
					dataIndex: 'stock',
					width: 120
				}, {
					title:'价格信息',
					key: 'price',
					width: 120
				}, {
					title: '卡信息',
					key: 'card',
					width: 250
				}, {
					title: '券信息',
					key: 'coupon',
					width: 250
				}, {
					title: '操作',
					key: 'action',
				}]
			}
		},
		created() {
			this.getOrganizationList();
			// this.getAllCinemaList();
		},
		methods: {
			onBack(isRef) {
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.id = this.searchData.id ? this.searchData.id : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCinemaCardGoodsList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.getData();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.id = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async onSubmit() {
				if (!this.goodsIds.length) {
					this.$message.error('请选择在线会员卡商品')
					return;
				}
				this.loading = true;
				let postData = {
					id: this.id,
					cardGoodsIds: this.goodsIds.join(',')
				};
				let ret = await updateCinemaCardGoods(postData);
				if (ret.code === 200) {
					this.$message.success('修改成功');
					this.loading = false;
					this.visible = false;
					this.getData();
				}
			},
      onDelete(record, index) {
        this.$confirm({
          title: '提示',
          content: '确定删除吗？确定后，需删除后需点击弹窗右下角进行保存。',
          onOk: async () => {
            this.goodsIds.splice(index,1);
            this.goodsList.splice(index,1);
          },
        })
        // console.log(record, index)
      },
			onEdit(item) {
				this.editData = JSON.parse(JSON.stringify(item));
				this.id = item.id;
        this.goodsIds = [];
        this.goodsList = [];
				if (item.goodsDetailList && item.goodsDetailList[0].storeGoodsStock) {
          item.goodsDetailList.forEach((item,index)=>{
            console.log(item)
            this.goodsIds.push(item.storeGoodsStock.id)
            this.goodsList.push(item.storeGoodsStock)
          })
					// this.goodsIds = [item.goodsDetail.storeGoodsStock.id];
					// this.goodsList = [item.goodsDetail.storeGoodsStock];
				} else {
					this.goodsIds = [];
					this.goodsList = [];
				}
				this.visible = true;
			},
			onGoodsListChange(data) {
				this.goodsList = data;
			},
		}
	}
</script>

<style>
	.ui-form__item {
		margin-right: 30px;
	}
  .detailItem{
    width: 400px;
    margin: 10px 0;
    padding: 20px;
    border: 1px solid #f0f0f0;
  }
</style>
